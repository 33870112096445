"use client";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  zoom_documentation_route,
  terms_of_use,
  cookies_policy,
  desclaimer_route,
  user_agreement_route,
} from "@/constants/route_constants";
import { ADD_NEWSLETTER, SEND_MAIL_BY_CONTACTUS } from "@/api/apiUrl";
import axios from "@/api/axios";
import { toast } from "react-toastify";
import Link from "next/link";
import Image from "next/image";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Popup from "./Popup";
import mailTracker from "@/utility/mailTracker";

const NewFooter = () => {
  const { token } = useSelector((state) => state.user);
  const { t } = useTranslation();

  const language = localStorage.getItem("selected_language");

  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitContact, setIsSubmitContact] = useState(false);
  const [NewsLetterForm, setNewsLetterForm] = useState({
    email: "",
  });

  const [ContactusFrom, setContactusFrom] = useState({
    first_name: "",
    email: "",
    // country_code: "",
    contact_No: "",
    subject: "",
    message: "",
  });

  const addNewsLetter = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (NewsLetterForm.email === "") {
      toast.error("Email is required");
      setIsSubmit(false);
      return false;
    }
    try {
      await axios.post(ADD_NEWSLETTER, NewsLetterForm, {
        headers: { Authorization: "Bearer " + token },
      });
      toast.success(t("Successfully Registered for Newsletter"));
      setIsSubmit(false);
    } catch (error) {
      toast.error(error.response?.data?.msg || "Something is wrong!");
      setIsSubmit(false);
    }
  };

  const formhandling = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setNewsLetterForm({ ...NewsLetterForm, [name]: value });
  };

  const Contactformhandling = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setContactusFrom({ ...ContactusFrom, [name]: value });
  };

  const sendMail = async (e) => {
    e.preventDefault();
    setIsSubmitContact(true);

    if (!ContactusFrom.first_name) {
      toast.error(t("Name is required"));
      setIsSubmitContact(false);
      return false;
    }
    if (ContactusFrom.contact_No.length <= 5) {
      toast.error("Contact No is required");
      setIsSubmitContact(false);
      return false;
    }
    if (ContactusFrom.email === "") {
      toast.error("Email is required");
      setIsSubmitContact(false);
      return false;
    }
    // if (ContactusFrom.message === "") {
    //   toast.error("Message is required");
    //   setIsSubmitContact(false);
    //   return false;
    // }

    try {
      mailTracker("contact-us-mail-tracker");
      await axios.post(SEND_MAIL_BY_CONTACTUS, ContactusFrom);

      toast.success("Mail sent successfully");
      setIsSubmitContact(true);
      setContactusFrom({
        first_name: "",
        email: "",
        // country_code: "",
        contact_No: "",
        subject: "",
        message: "",
      });
      return true;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.msg || "Something is wrong!");
      setIsSubmitContact(false);
      return false;
    }
  };

  const setPhone = (value) => {
    setContactusFrom({ ...ContactusFrom, contact_No: value });
  };

  return (
    <>
      <Popup
        sendMail={sendMail}
        ContactusFrom={ContactusFrom}
        Contactformhandling={Contactformhandling}
        language={language}
        setPhone={setPhone}
        isSubmitContact={isSubmitContact}
      />

      <div className="whatsapp-float whatsapp-float-message">
        <span className="message">{t("Contact Us")}</span>
      </div>
      <div className="whatsapp-float">
        <a
          href="https://wa.me/966570123789"
          target="_blank"
          rel="noreferrer"
          aria-label="whatsapp">
          <i className="fa fa-whatsapp"></i>{" "}
        </a>
      </div>

      <section id="contact-us" className="contact_area contact_area_2 row ">
        <div className="left_contact col-md-6 col-12 ">
          <div className="text-light mt-5 d-block d-md-none">
            <h2>
              <span>{t("Request")}</span> {t("Free Consultation")}
            </h2>
          </div>
          <div className="contact_from_area">
            <form className="row from_main" onSubmit={sendMail}>
              <div className="form-group col-12">
                <input
                  autoComplete="on"
                  type="text"
                  name="first_name"
                  value={ContactusFrom.first_name}
                  onChange={(e) => Contactformhandling(e)}
                  className="form-control"
                  placeholder={t("Name*")}
                />
              </div>
              <div
                className="form-group col-12 "
                dir={language === "en" ? "rtl" : "ltr"}
                language={language}>
                <PhoneInput
                  inputProps={{
                    name: "contact_No",
                    style: {
                      width: "100%",
                      direction: "ltr",
                    },
                    dir: "ltr",
                    language,
                  }}
                  defaultCountry="US"
                  value={ContactusFrom.contact_No}
                  onChange={setPhone}
                  name="contact_No"
                  // className="form-control"
                  placeholder={t("Phone Number*")}
                  autoComplete="on"
                  style={{ flex: 1, width: "100%" }}
                  // localization={language}
                  language={language}
                  // dir={language === "en" ? "ltr" : "rtl"}
                  // autoFormat={false}
                  dir="ltr"
                  containerStyle={{
                    direction: "ltr",
                  }}
                  buttonClass="text-center bg-transparent border-0"
                />
              </div>
              <div className="form-group col-12">
                <input
                  autoComplete="on"
                  type="email"
                  name="email"
                  value={ContactusFrom.email}
                  onChange={(e) => Contactformhandling(e)}
                  className="form-control"
                  placeholder={t("Email Address*")}
                />
              </div>
              <div className="form-group col-12">
                <textarea
                  name="message"
                  value={ContactusFrom.message}
                  onChange={(e) => Contactformhandling(e)}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  placeholder={t("Write your message")}></textarea>
              </div>

              <div className="form-group col-12">
                <button
                  type="submit"
                  className="find_btn btn"
                  disabled={isSubmitContact}>
                  {t("Book Now")}
                </button>

                <div
                  id="js-contact-result"
                  data-success-msg="Form submitted successfully."
                  data-error-msg="Messages Successfully"></div>
              </div>
            </form>
          </div>
        </div>

        <div className="right_contact col-md-6 d-none d-md-block">
          <div className="request_area" style={{ height: "100%" }}>
            <div className="request" style={{ height: "100%" }}>
              <Image
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                  height: "100%",
                }}
                loading="lazy"
                width={845}
                height={550}
                src="/new_assets/images/contact-form.jpg"
                alt="raad contact form"
              />
              <div className="request_content">
                <h2>
                  <span>{t("Request")}</span> {t("Free Consultation")}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer_area">
        <div className="container">
          <div className="row m-0 footer_subscriber">
            <div className="col-lg-4 find_out_text p-0">
              <h2>{t("Find out latest news & offers")}</h2>
            </div>
            <div className="form-group col-lg-8 p-0">
              <form onSubmit={addNewsLetter}>
                <input
                  autoComplete="on"
                  type="email"
                  name="email"
                  value={NewsLetterForm.email}
                  onChange={(e) => formhandling(e)}
                  className="form-control"
                  placeholder={t("Email Address*")}
                />
                {!isSubmit ? (
                  <button className="find_btn btn" type="submit">
                    {t("subscribe now")}
                  </button>
                ) : (
                  <button className="find_btn btn" disabled>
                    {t("subscribe now")}...{" "}
                    <span className="fa fa-spinner fa-spin  loader"></span>
                  </button>
                )}
              </form>
            </div>
          </div>
          <div className="row footer_row">
            <div className="col-md-6 fooer_logo">
              <a href="/">
                <Image
                  loading="lazy"
                  width={244}
                  height={35}
                  src="/new_assets/images/logo100bw.png"
                  alt="raad logo"
                />
              </a>
              <p className="text-justify">{t("footer description raad")}</p>
            </div>
            <div className="col-md-3 quick quick_strat">
              <h4>{t("Quick Links")}</h4>
              <ul className="quick_links">
                <li>
                  <Link href={terms_of_use}>- {t("Terms of Use")}</Link>
                </li>
                <li>
                  <Link href="/privacy-policy">- {t("privacy_policy")}</Link>
                </li>
                <li>
                  <Link href={zoom_documentation_route}>
                    - {t("zoom_documents")}
                  </Link>
                </li>
                <li>
                  <Link href="/about-us">- {t("about_us")}</Link>
                </li>
                <li>
                  <Link href={user_agreement_route}>
                    - {t("User Agreement")}
                  </Link>
                </li>
                <li>
                  <Link href={cookies_policy}>- {t("Cookies Policy")}</Link>
                </li>
                <li>
                  <Link href={desclaimer_route}>- {t("Disclaimer")}</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 quick quick_strat">
              <h4>{t("Get in Touch")}</h4>
              <ul className="quick_links">
                <li>
                  <a
                    href="#"
                    // href="javascript:void(0)"
                  >
                    <i className="fa fa-map-marker"></i>&nbsp; 305,1-11-15
                    Yatsu, Narashino-shi, Chiba-ken Japan 〒275-0026
                  </a>
                </li>
                <li className="mt-3 mb-3">
                  <a href="mailto:contact@raaddojo.com">
                    <i className="fa fa-envelope-o"></i> contact@raaddojo.com
                  </a>
                </li>
                {language == "en" ? (
                  <li>
                    <a href="tel:+966570123789">
                      <Image
                        loading="lazy"
                        src="/new_assets/images/KSA.png"
                        width={30}
                        height={30}
                        alt="KSA phone"
                      />{" "}
                      +96 65701 23789
                    </a>
                  </li>
                ) : (
                  <li>
                    <a href="tel:+966570123789">
                      <Image
                        loading="lazy"
                        src="/new_assets/images/KSA.png"
                        width={30}
                        height={30}
                        alt="KSA phone"
                      />{" "}
                      {"966570123789"}+
                    </a>
                  </li>
                )}
                {language == "en" ? (
                  <li className="mt-2">
                    <a href="tel:+819097527341" style={{ color: "#8d8d8d" }}>
                      <Image
                        loading="lazy"
                        src="/new_assets/images/Jaapan.png"
                        width={30}
                        height={30}
                        alt="Jaapan phone"
                      />{" "}
                      +81 90975 27341
                    </a>
                  </li>
                ) : (
                  <li className="mt-2 ms-2">
                    <a href="tel:+819097527341" style={{ color: "#8d8d8d" }}>
                      <Image
                        loading="lazy"
                        src="/new_assets/images/Jaapan.png"
                        width={30}
                        height={30}
                        alt="Jaapan phone"
                      />{" "}
                      {"819097527341"}+
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="copy_right">
          <div className="container">
            <h6> {t("© 2024 RaadDojo. All rights reserved.")} </h6>
            <ul className="social_icon">
              <li>
                <a
                  href="https://www.instagram.com/raad_dojo"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram">
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/raaddojo"
                  target="_blank"
                  aria-label="Facebook"
                  rel="noreferrer">
                  <i className="fa fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@raaddojo"
                  target="_blank"
                  aria-label="YouTube"
                  rel="noreferrer">
                  <i className="fa fa-youtube-play"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default NewFooter;
