export const home_route = "/";
export const about_us_route = "/about-us";
export const our_strategy_route = "/our-strategy";
export const our_packages_route = "/our-packages";
export const privacy_policy_route = "/privacy-policy";
export const profile_route = "/profile";
export const edit_profile_route = "/edit-profile";
export const admin_login = "/admin/login";
export const admin_dashboard = "/admin/dashboard";
export const client_profile = "/admin/client-profile/";
export const coach_profile = "/admin/coach-profile/";
export const all_coaches = "/admin/all-coaches";
export const all_users = "/admin/all-users";
export const admin_logout_users = "/admin/logout";
export const add_coach = "/admin/add-coach";
export const setting_route = "/admin/settings";
export const blogs_route = "/admin/blogs";
export const all_admin = "/admin/all";
export const add_admin = "/admin/add-admin";
export const add_package_route = "/admin/add-package";
export const all_package_route = "/admin/all-package";
export const package_details_route = "/admin/package-details/";
export const edit_package_route = "/admin/edit-package/";
export const add_plan_route = "/admin/add-plan";
export const edit_plan_route = "/admin/edit-plan/";
export const all_plan_route = "/admin/all-plan";
export const plan_details_route = "/admin/plan-details/";
export const user_package_route = "/package";
export const invoice_list_route = "/admin/invoice";
export const user_plan_route = "/package-plan-details/";
export const user_workout_plan_route = "/package-workout-plan-details/";
export const user_planbyorder_route = "/package-plan-detail/";
export const user_workout_planbyorder_route = "/package-workout-plan-detail/";
export const add_workout_plan_route = "/admin/add-workout-plan";
export const getall_workout_plan_route = "/admin/all-workout-plan";
export const workout_plan_details_route = "/admin/details-workout-plan/";
export const edit_workout_plan_route = "/admin/edit-workout-plan/";
export const chat = "/chat";
export const admin_chat_route = "/admin/admin-chat";
export const class_booking_group_route = "/booked-class-group";
export const online_classes = "/online-classes";
export const zoom_documentation_route = "/zoom-documentation";
export const BankDetails_route = "/bank-details";
export const contact_us = "/Contact-us";
export const terms_of_use = "/terms-of-use";
export const cookies_policy = "/cookines-policy";
export const desclaimer_route = "/desclaimer";
export const user_agreement_route = "/user-agreement";
export const reward_offer_route = "/reward-offer";
export const reward_offer_page_one = "/reward-offer-level";
export const reward_offer_page_two = "/reward-offer-level/";
export const add_review_point = "/users/addReviewPoints";
export const user_blogs_route = "/blogs";

// Admin Class
export const add_class_plan_route = "/admin/add-class-plan";
export const all_class_plan_route = "/admin/all-class-plan";
export const get_class_plan_detail = "/admin/class/";
export const edit_class_plan = "/admin/edit-class-plan/";
export const Payment_tracking_route = "/admin/payment-tracking";
export const Edit_client_profile = "/admin/edit-client-profile/";

// coach
export const coach_availability = "/coach-availability";
export const coach_rewards_routes = "/rewards";

// export const add_class_plan_route='/admin/add-class-plan';

//Zoom

//rewards
export const admin_rewards_offer = "/admin/rewards-offer";
export const admin_add_rewards_offer = "/admin/add-rewards-offer";
export const admin_edit_rewards_offer = "/admin/edit-rewards-offer/";
export const rewards_offer_routes = "/rewards_offfer";
export const refrale_page_route = "/refrale-page";
export const admin_redeem_request_route = "/admin/redeem-amount";
export const admin_redeem_offer_request_route = "/admin/redeem-offer";
export const admin_offer_assign_route = "/admin/assign-offer-list";
export const admin_assign_route = "/admin/assign";
export const update_assign_offer_route = "/admin/update-assign/";
export const All_newsLetter_route = "/admin/newsletter";
export const add_quiz_question = "/admin/add-question";
export const admin_quiz_question = "/admin/question";
export const edit_quiz_question = "/admin/edit-question/";
export const user_quiz_question = "/quiz";
export const quiz_result_route = "/admin/quiz-answer";
export const invoice_page = "/admin/invoice/";

//Rating
export const review_list = "/admin/rating-list";
