import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";

const Popup = ({
  sendMail,
  ContactusFrom,
  Contactformhandling,
  language,
  setPhone,
  isSubmitContact,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const authUser = JSON.parse(localStorage.getItem("AuthUser"));
  useEffect(() => {
    const timer = setTimeout(() => {
      !authUser && setOpen(true);
    }, 5000); // Open after 5 seconds

    return () => clearTimeout(timer); // Clear the timer if the component is unmounted
  }, []);

  const handleSubmit = async (e) => {
    const res = await sendMail(e);
    res && setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        {" "}
        <span>{t("Request")}</span> {t("Free Consultation")}
      </DialogTitle>
      <DialogContent>
        <form className="row from_main" onSubmit={handleSubmit}>
          <div className="form-group col-12">
            <input
              autoComplete="on"
              type="text"
              name="first_name"
              value={ContactusFrom.first_name}
              onChange={(e) => Contactformhandling(e)}
              className="form-control"
              placeholder={t("Name*")}
            />
          </div>
          <div
            className="form-group col-12 "
            dir={language === "en" ? "rtl" : "ltr"}
            language={language}>
            <PhoneInput
              inputProps={{
                name: "contact_No",
                style: {
                  width: "100%",
                  direction: "ltr",
                },
                dir: "ltr",
                language,
              }}
              defaultCountry="US"
              value={ContactusFrom.contact_No}
              onChange={setPhone}
              name="contact_No"
              // className="form-control"
              placeholder={t("Phone Number*")}
              autoComplete="on"
              style={{ flex: 1, width: "100%" }}
              // localization={language}
              language={language}
              // dir={language === "en" ? "ltr" : "rtl"}
              // autoFormat={false}
              dir="ltr"
              containerStyle={{
                direction: "ltr",
              }}
              buttonClass="text-center bg-transparent border-0"
            />
          </div>
          <div className="form-group col-12">
            <input
              autoComplete="on"
              type="email"
              name="email"
              value={ContactusFrom.email}
              onChange={(e) => Contactformhandling(e)}
              className="form-control"
              placeholder={t("Email Address*")}
            />
          </div>
          <div className="form-group col-12">
            <textarea
              name="message"
              value={ContactusFrom.message}
              onChange={(e) => Contactformhandling(e)}
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              placeholder={t("Write your message")}></textarea>
          </div>

          <div className="form-group col-12">
            <button
              type="submit"
              className="find_btn btn"
              disabled={isSubmitContact}>
              {t("Book Now")}
            </button>
            <div
              id="js-contact-result"
              data-success-msg="Form submitted successfully."
              data-error-msg="Messages Successfully"></div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
